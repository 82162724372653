import pic from "./assets/pic.jpg";
import "./style/App.css";
import Education from "./components/Education";
import Work from "./components/Work";
import Certifications from "./components/Certifications";
import Theses from "./components/Theses";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="header-container">
      <header class="header"></header>
      <img src={pic} alt="Profile" className="profile-image" />

      <div className="bio">
        <h1 className="title">Yaman Sanobar</h1>
        <div className="intro">
          {" "}
          I have a multidisciplinary background in the theoretical research and
          the technical development of designing and developing{" "}
          <strong>applications</strong>. I have been involved in numerous
          projects with multidisciplinary teams, which shaped my interest in the
          app development field. My experience spans both academic research and
          hands-on technical roles, allowing me to bridge the gap between theory
          and practice. This unique blend enables me to approach development
          with a comprehensive perspective on both user needs and technological
          innovations.{" "}
        </div>
        <div className="spacing"></div>
        <div className="spacing"></div>
        <Work />
        <Education />
        <Theses />
        <Certifications />
        <div className="spacing"></div>
        <div className="spacing"></div>
        <div className="spacing"></div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
